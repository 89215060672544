$white: #ffffff;
$green: #66b238;
$yellow: #ffd430;
$red: #e60012;
$black: #333;
$dark: #1a1a1a;
$orange: #f48604;
$light-black: #999;
$gray: #666;
$border: #ededed;
$blue: #6483bb;