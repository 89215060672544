@mixin createIcon($icon,$width:10px,$height:10px) {
    display: inline-block;
    background: url($icon) no-repeat center;
    width: $width;
    height: $height;
    background-origin:content-box;
    background-size: contain;
    vertical-align: middle;
}

.icon-collection-b{
    @include createIcon('../img/collection-b.png', 20px, 19px);
}
.icon-collection-o{
    @include createIcon('../img/collection-o.png', 20px, 19px);
}
.icon-border-collection-o{
    @include createIcon('../img/border-collection-o.png', 16px, 14px);
}
.icon-shopping-b{
    @include createIcon('../img/shopping-b.png', 23px, 20px);
}
.icon-shopping-o{
    @include createIcon('../img/shopping-o.png', 23px, 20px);
}
.icon-download-b{
    @include createIcon('../img/download-b.png', 22px, 20px);
}