.qs-form{
    .ant-input{
        border: none;
        background-color: #ebebeb;
    }
    &.ant-form-large{
        .ant-form-item-label > label {
            height: 30px;
        }
        .ant-input{
			height: 42px;
			font-size: 14px;
        }
        .ant-form-item {
            margin-bottom: 12px;
        }
    }
    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        background-color: #ebebeb;
	}
	&.horizontal{
		.ant-form-item-label > label {
			height: 40px;
        }
		.ant-form-item {
            margin-bottom: 30px;
        }
	}
}

.as-a{
    cursor: pointer;
}
.text-blue {
    color: #418ef7;
}

.antd-btn {
    &.large {
        height: 42px;
        font-size: 14px;
    }
    &.middle {

    }
    &.small {

    }
    &.btn-orange{
        color: #4d4d4d;
        background-color: $yellow;
        border: none;
    }
}
/* ==================
          按钮
 ==================== */

 .cu-btn {
	position: relative;
	border: 0px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 0 30px;
	font-size: 28px;
	height: 64px;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	overflow: visible;
	margin-left: initial;
	transform: translate(0px, 0px);
	margin-right: initial;
}

.cu-btn::after {
	display: none;
}

.cu-btn:not([class*="bg-"]) {
	background-color: #f0f0f0;
}

.cu-btn[class*="line"] ,.cu-btn[class*="pure"]{
	background-color: transparent;
}

.cu-btn[class*="line"]::after {
	content: " ";
	display: block;
	width: 200%;
	height: 200%;
	position: absolute;
	top: 0;
	left: 0;
	border: 1px solid currentColor;
	transform: scale(0.5);
	transform-origin: 0 0;
	box-sizing: border-box;
	border-radius: 12px;
	z-index: 1;
	pointer-events: none;
}
.cu-btn[class*="pure"]::after {
	content: " ";
	display: block;
	width: 200%;
	height: 200%;
	position: absolute;
	top: 0;
	left: 0;
	border: 0px solid #e6e6e6;
	transform: scale(0.5);
	transform-origin: 0 0;
	box-sizing: border-box;
	z-index: 1;
	pointer-events: none;
}

.cu-btn.round[class*="line"]::after {
	border-radius: 1000px;
}

.cu-btn[class*="lines"]::after {
	border: 6px solid #e6e6e6;
}

.cu-btn[class*="bg-"]::after {
	display: none;
}

.cu-btn.sm {
	padding: 0 16px;
	font-size: 14px;
	height: 34px;
}
.cu-btn.qs-sm{
	padding: 0px 38px;
	font-size: 32px;
	height: 56px;
}
.cu-btn.qs-xs{
	padding: 0 22px;
	font-size: 24px;
	height: 42px;
}
.cu-btn.lg {
	padding: 0 40px;
	font-size: 32px;
	height: 80px;
}
.cu-btn.round{
	border-radius: 50px;
}
.cu-btn.s-round{
	border-radius: 3px;
}
.cu-btn.w-580{
	width: 580px;
	height: 100px;
}
.cu-btn.w-120{
	font-size: 28px;
	width: 120px;
	height: 50px;
}
.cu-btn.w-130{
	font-size: 24px;
	width: 130px;
	height: 42px;
	padding: 0;
}
.cu-btn.w-66{
	font-size: 12px;
	width: 66px;
	height: 26px;
	padding: 0;
}
.cu-btn.w-136{
	font-size: 16px;
	width: 136px;
	height: 40px;
	padding: 0;
}
.cu-btn.w-180{
	font-size: 28px;
	width: 180px;
	height: 60px;
}
.cu-btn.w-186{
	font-size: 26px;
	width: 186px;
	padding: 0;
}
.cu-btn.w-200{
	width: 200px;
	height: 70px;
	font-size: 28px;
}
.cu-btn.w-260{
	width: 260px;
	height: 40px;
	font-size: 16px;
}
.cu-btn.w-360{
	width: 360px;
	height: 70px;
	font-size: 32px;
}
.cu-btn.w-330{
	width: 330px;
	height: 70px;
	font-size: 32px;
}
.cu-btn.block{
	width: 100%;
	font-size: 32px;
	height: 88px;
}

.cu-btn.cuIcon.sm {
	width: 48px;
	height: 48px;
}

.cu-btn.cuIcon {
	width: 64px;
	height: 64px;
	border-radius: 500px;
	padding: 0;
}

button.cuIcon.lg {
	width: 80px;
	height: 80px;
}

.cu-btn.shadow-blur::before {
	top: 4px;
	left: 4px;
	filter: blur(6px);
	opacity: 0.6;
}

.cu-btn.button-hover {
	transform: translate(1px, 1px);
}

.block {
	display: block;
}

.cu-btn.block {
	display: flex;
}

.cu-btn[disabled] {
	opacity: 0.6;
	color: #ffffff;
}

.text-cut {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.text-cut-2 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.text-cut-3 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.container{
    width: 1180px;
    margin: 0 auto;
}
.search-wrap{
    width: 520px;
    margin: 40px auto;
}
.base-grid{
    @extend .flex;
    @extend .justify-between;
    .l{
        flex-shrink: 0;
    }
    .r{
        flex: 1;
    }
    &.l-30{
        .l {
            width: 260px;
            margin-right: 43px;
        }
	}
	&.l-32{
		.l{
			width: 270px;
			margin-right: 30px;
		}
	}
}
.base-section{
    width: 100%;
    padding: 30px 50px;
    box-shadow: 0px 4px 20px 0px 
		rgba(0, 0, 0, 0.14);
	border-radius: 6px;
}
.flex {
	display: flex;
}

.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: flex-center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}
.flex-center{
  @extend .flex;
  @extend .align-center;
  @extend .justify-center;
}
.l-img-r-content{
	display: flex;
	.img{
		flex-shrink: 0;
	}
	.content{
		flex: 1;
	}
}

.bg-orange {
	color: #4d4d4d;
    background-color: $orange;
}
.bg-yellow {
	color: #4d4d4d;
    background-color: $yellow;
}
.bg-gray {
	color: #4d4d4d;
    background-color: #ededed;
}
.bg-white {
	background-color: #ffffff;
}

.mb30{
	margin-bottom: 30px;
}
.mb18{
	margin-bottom: 18px;
}
.m-modal {
	.ant-modal-content{
		position: relative;
		border-radius: 6px;
		.ant-modal-footer{
			display: none;
		}
		.ant-modal-body{
			padding: 58px 40px;
		}
		.ant-modal-close{
			position: absolute;
			top: -40px;
			> .ant-modal-close-x{
				width: 30px;
				height: 30px;
				border: solid 1px #ffffff;
				color: #fff;
				line-height: 30px;
				border-radius: 50%;
			}
		}
		.m-notice-modal-content{
			text-align: center;
			.modal-title{
				font-size: 24px;
				color: $black;
				margin-bottom: 24px;
			}
			.modal-desc{
				font-size: 14px;
				color: $gray;
			}
		}
	}
	.btn-group{
		margin-top: 30px;
		.cu-btn{
			& + .cu-btn{
				margin-left: 20px;
			}
		}
	}
	&.s-m-modal{
		.ant-modal-content{
			.ant-modal-body{
				padding: 30px;
			}
		} 
	}
}
.ant-pagination{
	text-align: center;
	margin-top: 60px;
	margin-bottom: 60px;
}

.text-orange{
	color: $orange;
}
.text-yellow {
	color: $yellow;
}
.text-gray{
	color: $gray;
}
.text-light-black{
	color: $light-black;
}
.ossuploader-add, .ossuploader-dash-border{
	border-radius: 50%;
	overflow: hidden;
	width: 80px;
	height: 80px;
}
.ossuploader-add p{
	display: none;
}
.mt20{
	margin-top: 20px;
}
.ml10{
	margin-left: 10px;
}
.ml6{
	margin-left: 6px;
}
.hidden{
	display: none;
}
.ant-btn-primary:hover, .ant-btn-primary:focus{
	color: #4d4d4d;
    background-color: #ffd430;
	border-color: unset;
}