@import '~antd/dist/antd.css';
@import './var.scss';
@import './common.scss';
@import './icon.scss';
@import '~video-react/styles/scss/video-react';
.m-pagination{
    font-size: 16px;
    .ant-pagination-total-text,.ant-pagination-options-quick-jumper{
        color: $gray;
    }
    .ant-pagination-prev,.ant-pagination-next{
        >.ant-pagination-item-link{
            border: none;
            background-color: transparent;
            font-size: 18px;
        }
    }
    .ant-pagination-prev{
        margin-right: 20px;
    }
    .ant-pagination-item{
        min-width: 28px;
        height: 28px;
        line-height: 28px;
        background-color: transparent;
        border: none;
        margin-right: 24px;
        > a{
            color: $gray;
        }
    }
    .ant-pagination-item-active{
        background-color: $light-black;
        border: none;
        a {
            color: #fff;
        }
    }
}
.detail-modal{
    .ant-modal-content{
        position: relative;
        .ant-modal-close{
            position: absolute;
        }
       .ant-modal-footer{
           display: none;
       }
       .ant-modal-body{
           padding: 34px 40px;
       }
    }
}
.login-wrap{
    width: 420px;
    color: #1a1a1a;
    font-size: 28px;
    padding: 44px 0;
    .title{
        margin-bottom: 46px;
    }
    > img{
        max-width: 200px;
        height: auto;
    }
    .tips{
        margin-top: 46px;
        font-size: 16px;
        color: #666666;
    }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    top: 23px;
    color: #1a1a1a;
    padding-top: 0;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra{
    position: absolute;
    top: 16px;
    left: 20px;
    z-index: 2;
}
.s-collapse-wrap{
    border: none;
    background-color: #fafafa;
    .s-collapse-value{
        padding: 4px 22px;
        color: #808080;
        display: flex;
        .count{
            margin-left: 6px;
            color: #999999;
        }
        > .icon{
            width: 11px;
            height: 11px;
        }
        > .text{
            margin-left: 14px;
        }
        &.active,&:hover{
            color: $orange;
            > .count{
                color: $orange;
            }
        }
    }
    .ant-collapse-item {
        border-bottom: none;
        > .ant-collapse-header {
            padding: 16px 20px;
            font-size: 14px;
            color: #1a1a1a;
            // background-color: #ededed;
            .ant-collapse-arrow {
                right: 16px;
                left: unset;
            }
        }
        //高亮
        // &.ant-collapse-item-active{
        //     > .ant-collapse-header{
        //         color: #f48604;
        //     }
        // }
        &.active{
            > .ant-collapse-header{
                color: $orange;
                .ant-collapse-extra{
                    color: $orange;
                }
            }
        }
    }
    .ant-collapse-content{
        border-top: none;
        background-color: #fafafa;
        > .ant-collapse-content-box{
            padding: 0 0 0 13px;
        }
    }
    .ant-collapse{
        border: none;
    }
    &-1{
        > .ant-collapse-item{
            > .ant-collapse-header{
                font-size: 16px;
            }
        }
    }
    &-2{
        > .ant-collapse-item{
            > .ant-collapse-header{
                background-color: #ededed;
                position: relative;
                &:before{
                    content: '';
                    display: inline-block;
                    top: 0;
                    position: absolute;
                    left: -13px;
                    width: 13px;
                    background-color: #ededed;
                    height: 100%;
                }
            }
        }
    }
}
.with-close{
    .tag{
        border: none;
        background: none;
        position: relative;
        .ant-tag-close-icon{
            padding: 3px;
            background-color: #cccccc;
            border-radius: 50%;
            margin-left: 5px;
            color: #fff;
        }
    }
}
.base-tag{
    .tag {
       padding: 6px 10px;
       font-size: 14px;
       &.tag-radius{
        border-radius: 3px;
       }
       &.tag-orange{
        background-color: #fff0df;
        color: $orange;
       }
       &.tag-gray{
        color: #4d4d4d;
        background-color: $border;
       }
    }
}
.index-menu-operation{
    padding-bottom: 10px;
    margin-bottom: 18px;
    border-bottom: 1px solid $border;
}
.material-list{
    display: flex;
    @extend .flex-wrap;
    > .item {
        overflow: hidden;
        // border: 1px solid $border;
        height: 290px;
        position: relative;
        cursor: pointer;
        background-color: #ffffff;
	    box-shadow: 0px 1px 10px 0px
        rgba(0, 0, 0, 0.18);
        width: 272px;
        margin-bottom: 30px;
        .img-box{
            width: 272px;
            height: 180px;
            overflow: hidden;
            background-color: #000;
        }
        .check-box-wrap{
            position: absolute;
            display: none;
            right: 0;
            top: 0;
            .checkbox{
                cursor: pointer;
                width: 28px;
	            height: 28px;
            }
        }
        .img{
            width: 100%;
	        height: auto;
        }
        .content{
            padding: 12px 16px;
            padding-bottom: 18px;
            font-size: 16px;
            color: #1a1a1a;
            .title{
                line-height: 1.7;
                height: 54px;
                margin-bottom: 6px;
            }
            .menu-bottom{
                @extend .flex;
                justify-content: flex-end;
                font-size: 24px;
                .icon {
                    cursor: pointer;
                    & + .icon{
                        margin-left: 20px;
                    }
                    &:hover{
                        color: $orange;
                    }
                }
            }
        }
        &.show-batch{
            .check-box-wrap{
                display: inline-block;
            }
        }
        &.active{
            border: solid 1px #f48604;
            box-shadow: none;
        }
    }
    &.n-3{
        > .item{
            &:not(:nth-child(3n)) {
                margin-right: calc(60px / 2);
            }
        }
    }
    &.n-4{
        > .item{
            &:not(:nth-child(4n)) {
                margin-right: calc(92px / 3);
            }
        }
    }
}
.material-list-detail-wrap{
    .title-wrap{
        display: flex;
        margin-bottom: 6px;
        justify-content: space-between;
        @extend .align-center;
        .title{
            font-size: 22px;
            color: #1a1a1a;
            font-weight: bold;
        }
        .r-collection{
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: #fff0df;
            border-radius: 3px;
            padding: 2px 10px;
            color: #f48604;
            font-size: 14px;
            > .icon{
                width: 16px;
                height: 14px;
            }
            > span{
                display: inline-block;
                margin-left: 8px;
            }
        }
    }
    .city-list{
        font-size: 14px;
        color: $light-black;
        > span{
            & + span{
                margin-left: 10px;
            }
        }
    }
    > .detail-wrap{
        margin-top: 28px;
        .img{
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid $border;
            width: 450px;
            height: 297px;
            margin-right: 46px;
            position: relative;
            > .video-mask{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,.1);
                width: 100%;
                height: 100%;
                @extend .flex;
                @extend .align-center;
                justify-content: center;
                .icon-wrap{
                    width: 38px;
                    height: 38px;
                    background-color: #ffffff;
                    opacity: 0.9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: #f48604;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        .content{
            position: relative;
            .title{
                font-size: 16px;
                color: $dark;
                margin-bottom: 10px;
            }
            .desc{
                font-size: 14px;
                line-height: 1.8;
                max-height: 167px;
                line-height: 1.8;
                overflow-y: auto;
                white-space: pre-wrap;
            }
            .btn-group{
                position: absolute;
                left: 0;
                bottom: 0;
                .cu-btn{
                    .icon {
                        margin-right: 10px;
                    }
                    & + .cu-btn{
                        margin-left: 30px;
                    }
                }
            }
        }
    }
    .cate-list-wrap{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid $border;
        margin-top: 38px;
        > .item {
            font-size: 14px;
            color: $light-black;
            flex: 0 0 25%;
            margin-top: 24px;
            > span{
                & + span{
                    margin-left: 10px;
                }
            }
            // &:nth-child(4n + 1) {
            //     flex: 0 0 30%;
            // }
        }
    }
}
.video-react .video-react-big-play-button{
    width: 38px;
    height: 38px;
    background-color: #ffffff;
    font-size: 24px;
    border-radius: 50%;
    color: #f48604;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.top-menu-wrap{
    display: flex;
    margin-bottom: 25px;
    border-bottom: 1px solid $border;
    justify-content: space-between;
    .tab-wrap{
        display: flex;
        color: #1a1a1a;
        font-size: 16px;
        .tab{
            position: relative;
            padding-bottom: 14px;
            &::after{
                content: '';
                display: inline-block;
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                width: 0%;
                background-color: $yellow;
            }
            &.active{
                &::after{
                    width: 100%;
                }
            }
            & + .tab{
                margin-left: 86px;
            }
            .ant-badge{
                font-size: 16px;
            }
        }
    }
    .back{
        font-size: 14px;
        color: $light-black;
    }
}
.form-section{
    background-color: #ffffff;
    padding: 28px;
    .title-wrap{
        border-bottom: 1px solid $border;
        display: flex;
        margin-bottom: 47px;
        .title{
            padding-bottom: 16px;
            color: #1a1a1a;
            font-size: 16px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                width: 100%;
                background-color: $yellow;
            }
        }
    }
    .btn-group{
        .cu-btn{
            &.bg-yellow{
                color: #4d4d4d;
                background-color: $yellow;
            }
            &.bg-orange{
                color: #4d4d4d;
                background-color: $orange;
            }
        }
    }
}
.sidebar-wrap{
    .avatar-wrap{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 24px 21px;
        background-color: $yellow;
        color: #1a1a1a;
        font-size: 14px;
        .img-wrap{
            border: 2px solid #fff;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;
            > img{
                max-width: 100%;
                height: auto;
            }
        }
    }
    .menu-wrap{
         padding: 15px 0;
         background-color: #fff;
         &-item{
             width: 100%;
             display: flex;
             align-items: center;
             justify-content: space-between;
             height: 62px;
             padding: 0 20px;
             .title {
                 > span{
                     margin-left: 6px;
                     color: $light-black;
                 }
             }
             > .anticon{
                 font-size: 14px;
                 color: #4d4d4d;
             }
             &.active,&:hover{
                 background-color: #ededed;
                 position: relative;
                 color: #1a1a1a;
                 &::after{
                     position: absolute;
                     content: '';
                     width: 3px;
                     height: 100%;
                     left: 0;
                     top: 0;
                     background-color: $yellow;
                 }
             }
         }
    }
}
.section-tips{
    font-size: 14px;
    color: $light-black;
    display: flex;
    align-items: center;
    > .anticon{
        margin-right: 6px;
    }
}
.download-list{
    > .item{
        padding: 18px 0;
        border-bottom: 1px solid $border;
        display: flex;
        justify-content: space-between;
        .l{
            flex-shrink: 0;
            width: 80%;
            font-size: 14px;
            .title{
                margin-bottom: 14px;
                width: 100%;
                @extend .text-cut;
            }
            .l-b-wrap{
                display: flex;
                @extend .align-center;
                color: $light-black;
                .size{
                    margin-right: 204px;
                }
                .date{
                    margin-right: 90px;
                }
            }
        }
        .r{
            flex: 1;
        }
    }
}
.no-data-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    font-size: 16px;
    color: $light-black;
    .text{
        margin-left: 10px;
    }
}
.collapse-checkbox{
    &.ant-checkbox-wrapper{
        margin-left: 0px;
    }
    display: flex;
    padding: 8px 30px;
    .ant-checkbox{
        margin-top: 4px;
    }
}
.range-date-wrap{
    .ant-collapse-content {
        padding-top: 6px;
        padding-bottom: 24px;
        > .ant-collapse-content-box{
            padding: 0 13px 0 13px;
            .ant-picker-range{
                background-color: #fff0df;
                border-radius: 3px;
                border: none;
                padding: 8px 11px;
                overflow: hidden;
                color: $orange;
                .ant-picker-input > input,.ant-picker-input > input::placeholder{
                    color: $orange;
                    font-size: 14px;
                }

                .ant-picker-separator,.ant-picker-suffix,.ant-picker-clear{
                    color: $orange;
                }
            }
        }
    }
}
.m-spin{
    &.ant-spin-nested-loading > div > .ant-spin{
        max-height: unset;
        height: 100vh;
        position: fixed;
    }
    .ant-spin-container::after{
        z-index: 100;
        position: fixed;
    }
}
.left-menu-title{
    > .anticon{
        position: absolute;
        top: 0%;
        padding: 12px;
        left: 2px;
        cursor: pointer;
    }
}
.m-download-modal{
    .title-wrap{
        border-bottom: 1px solid $border;
        display: flex;
        margin-bottom: 20px;
        .title{
            font-size: 16px;
            color: #1a1a1a;
            padding-bottom: 18px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 3px;
                width: 100%;
                background-color: $yellow;
            }
        }
    }
    .article-content{
        max-height: 400px;
        overflow-y: scroll;
    }
    .download-msg-wrap{
        border-radius: 6px;
        background-color: #fafafa;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .r{
            flex: 1;
            .line{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: $gray;
                & + .line{
                    margin-top: 4px;
                }
            }
        }
        > img{
            margin-right: 18px;
            width: 46px;
            height: auto;
        }
    }
    .tips{
        margin-top: 10px;
        text-align: right;
        font-size: 14px;
        margin-bottom: 14px;
        color: $gray;
    }
    .btn-group{
        text-align: right;
    }
}
.footer{
    padding-top: 40px;
    .inner-wrap{
        border-top: 1px solid #dbdbdb;
        width: 100%;
    }
    .container{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 1180px;
        .l {
            p:first-child{
               margin-right: 30px;
            }
        }
    }
    font-size: 14px;
    color: #666;
    a {
        color: #666;
    }
}
.ant-popover{
    position: fixed;
    &.m-popover{
        position: fixed;
    }
}
